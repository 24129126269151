<template>
  <v-card v-can="permissions?.viewPermission">
    <v-card-title class="text-center">
      <v-icon icon="mdi-paperclip" />
      Attachments

      <span class="float-right">
        <UploadFormModal
          v-if="$gates.hasPermission(permissions?.uploadPermission)"
          :url="uploadUrl"
          @uploaded="fetchAttachments"
        />
      </span>
    </v-card-title>

    <DeleteButton
      v-if="deleteUrl"
      v-model="deleteModal"
      :url="deleteUrl"
      no-button
      @deleted="fetchAttachments"
    />

    <v-card-text
      class="mb-4"
      style="max-height: 300px; min-height: 50px; overflow-y: auto"
    >
      <div class="d-flex justify-space-around mb-2 flex-wrap">
        <template v-for="attachment in attachments" :key="attachment.id">
          <div
            class="p-1 border m-1 d-flex justify-center align-center bg-light position-relative"
            style="width: 110px; height: 110px"
          >
            <img
              data-target="attachment-viewer"
              class="cursor-pointer"
              :data-src="attachment.url"
              :src="attachment.thumbnailUrl ?? defaultImage"
              :alt="attachment.name"
              style="max-width: 100px; max-height: 100px"
            />

            <v-btn
              v-can="permissions?.deletePermission"
              v-tooltip="'Delete'"
              class="position-absolute"
              style="bottom: 4px; right: 4px"
              size="small"
              variant="plain"
              icon="mdi-delete"
              color="red"
              @click="deleteAttachment(attachment.id)"
            />
          </div>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import DeleteButton from '@/components/general/DeleteButton.vue';
import UploadFormModal from '@/components/modal/general/UploadFormModal.vue';
import { getAttachments } from '@/services/api/attachment';
import { ref, computed } from 'vue';

const attachments = ref([]);
const selectedAttachment = ref(null);
const defaultImage = '/images/placeholder.png';

const deleteModal = ref(false);

const { sectionId, section, child, childId } = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },

  childId: {
    type: Number,
    default: null,
  },

  child: {
    type: String,
    default: null,
  },
});

const sectionPlural = computed(() => section + 's');

const childPlural = computed(() => {
  if (!childId) {
    return null;
  }

  switch (child) {
    case 'summary':
      return 'summaries';
    default:
      return child + 's';
  }
});

const deleteUrl = computed(() => {
  if (!selectedAttachment.value) {
    return null;
  }

  return route('api.v1.attachments.destroy', [selectedAttachment.value?.id]);
});

const uploadUrl = computed(() => {
  if (childId) {
    return route(
      'api.v1.' +
        sectionPlural.value +
        '.' +
        childPlural.value +
        '.files.upload',
      [sectionId, childId]
    );
  }

  return route('api.v1.' + sectionPlural.value + '.files.upload', [sectionId]);
});

const permissions = computed(() => {
  const permission = childId ? section + '.' + child : section;

  return {
    uploadPermission: permission + '.file.upload',
    viewPermission: permission + '.file.view',
    deletePermission: permission + '.file.delete',
  };
});

function fetchAttachments() {
  getAttachments({
    section: sectionPlural.value,
    sectionId,
    child: childPlural.value,
    childId,
  }).then((_attachments) => {
    attachments.value = _attachments;
  });
}

/**
 * @param {number} attachmentId
 */
function deleteAttachment(attachmentId) {
  selectedAttachment.value = attachments.value.find(
    (attachment) => attachment.id === attachmentId
  );

  if (selectedAttachment.value) {
    deleteModal.value = true;
  }
}

fetchAttachments();
</script>
