<template>
  <v-card>
    <v-data-table-server
      v-bind="tableAttrs"
      fixed-footer
      fixed-header
      v-on="tableListeners"
    >
      <template #top>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="6">Collections cases</v-col>
            <v-col cols="12" md="6" class="text-right">
              <v-btn
                v-can="'user.is_employee'"
                size="small"
                color="black"
                href="?legacy=1"
              >
                Legacy
              </v-btn>

              <v-btn
                v-can="'debt.create'"
                size="small"
                color="green"
                href="/debts/create"
              >
                Add debt collection
              </v-btn>

              <v-btn
                type="submit"
                form="debtFilters"
                size="small"
                color="blue"
                @click="fetchItems"
              >
                Search
              </v-btn>

              <v-btn
                v-can="'user.is_employee'"
                size="small"
                color="green"
                @click="exportExcel"
              >
                Export
              </v-btn>
            </v-col>
          </v-row>

          <v-form id="debtFilters" @submit.prevent="fetchItems">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="search.ref_num" label="Ref. nummer" />
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field v-model="search.dossier" label="Dossier" />
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                  v-bind="debtorAttrs"
                  label="Debiteur"
                  clearable
                  v-on="debtorListeners"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                  v-bind="creditorAttrs"
                  label="Crediteur"
                  clearable
                  v-on="creditorListeners"
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="search.status"
                  :items="statusOptions"
                  label="Status"
                  clearable
                  chips
                  multiple
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="search.rental"
                  :items="debtTypesOptions"
                  label="Type"
                  clearable
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="search.physical_locations"
                  :items="fileLocations"
                  label="File location"
                  clearable
                  chips
                  multiple
                />
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="extraHeaders"
                  :items="debtCalculationsItems"
                  label="Columns"
                  clearable
                  chips
                  multiple
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-title>
      </template>

      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        #[`item.${header.key}`]="{ item }"
      >
        {{ header.formatter(getFromDotNotation(item, header.key)) }}
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip :color="item.status_data?.status_color" close>{{
          getStatusText(item.status_data)
        }}</v-chip>
      </template>

      <template #[`item.ref_num`]="{ item }">
        <a
          class="text-reset font-weight-bold text-nowrap"
          :href="'/debts/' + item.debt_id"
        >
          {{ item.ref_num }}
        </a>
      </template>

      <template #[`item.debtor`]="{ item }">
        <div v-for="person in item.people" :key="person.person_id">
          <people-link
            v-if="[1, 2].includes(person.pivot.responsibility)"
            :person="person"
            bold
          />
        </div>

        <div
          v-for="department in item.departments"
          :key="department.department_id"
        >
          <department-link
            v-if="[1, 2].includes(department.pivot.responsibility)"
            :department="department"
            bold
          />
        </div>
      </template>

      <template #[`item.creditor`]="{ item }">
        <div v-for="person in item.people" :key="person.person_id">
          <people-link
            v-if="[3, 4].includes(person.pivot.responsibility)"
            :person="person"
            bold
          />
        </div>

        <div
          v-for="department in item.departments"
          :key="department.department_id"
        >
          <department-link
            v-if="[3, 4].includes(department.pivot.responsibility)"
            :department="department"
            bold
          />
        </div>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';

import statusOptions from '@/data/debtStatus.js';
import fileLocations from '@/data/fileLocations';
import debtCalculations from '@/data/debtCalculations.json';
import { useLinkAutocomplete } from '@/composables/autocomplete/link-autocomplete.js';
import usePaginationTable from '@/composables/table/table-pagination.js';

import { getFromDotNotation } from '@/utils/functions.js';
import { getDebts } from '@/services/api/debt.js';
import { formatMoney } from '@/utils/formatter/number.js';

import { ref, computed } from 'vue';

// loop through json
const debtCalculationsItems = Object.entries(debtCalculations).map(
  ([index, item]) => {
    return {
      value: index,
      title: item,
    };
  }
);

const debtTypesOptions = [
  { value: 0, title: 'Debt collection' },
  { value: 1, title: 'Regular collection' },
];

const search = ref({
  ref_num: null,
  dossier: null,
  status: [],
  rental: null,
  physical_locations: [],
  debtor_person_id: null,
  creditor_person_id: null,
  debtor_department_id: null,
  creditor_department_id: null,
  per_page: 10,
  page: 1,
  sortBy: [],
});

const {
  link: debtorObject,
  attrs: debtorAttrs,
  listeners: debtorListeners,
} = useLinkAutocomplete();

const {
  link: creditorObject,
  attrs: creditorAttrs,
  listeners: creditorListeners,
} = useLinkAutocomplete();

const extraHeaders = ref([
  'contract',
  'grand_total',
  'open_balance',
  'late_payment',
]);

const headers = computed(() => {
  let selectedExtraHeaders = extraHeaders.value.map((column) => {
    let foundColumn = debtCalculations[column];
    let isInteger = [
      'agreement_count',
      'notice_letter_count',
      'advice_letter_count',
    ].includes(column);

    return {
      title: foundColumn,
      key: `calculations.${column}`,
      align: 'end',
      formatter: isInteger ? ($value) => $value : formatMoney,
    };
  });

  return [
    { title: 'Ref. nummer', key: 'ref_num' },
    { title: 'Debiteur', key: 'debtor', sortable: false },
    { title: 'Crediteur', key: 'creditor', sortable: false },
    { title: 'Status', key: 'status', with: '150' },
    { title: 'File location', key: 'physical_location', with: '150' },
    ...selectedExtraHeaders,
  ];
});

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers: headers,
  sortBy: [
    { key: 'status', order: 'asc' },
    { key: 'ref_num', order: 'desc' },
  ],
  fetchRequest: getDebts,
  prepareSearch() {
    updateSearch();

    return {
      ...search.value,
    };
  },
});

function updateSearch() {
  search.value.debtor_person_id = null;
  search.value.debtor_department_id = null;
  search.value.creditor_person_id = null;
  search.value.creditor_department_id = null;

  if (debtorObject?.type && debtorObject.id) {
    if (debtorObject?.type == 'person') {
      search.value.debtor_person_id = debtorObject.id;
    } else if (debtorObject?.type == 'department') {
      search.value.debtor_department_id = debtorObject.id;
    }
  }

  if (creditorObject?.type && creditorObject.id) {
    if (creditorObject?.type == 'person') {
      search.value.creditor_person_id = creditorObject.id;
    } else if (creditorObject?.type == 'department') {
      search.value.creditor_department_id = creditorObject.id;
    }
  }
}

function exportExcel() {
  let url = new URL('/debts/export', window.location.origin);

  updateSearch();

  Object.keys(search.value).forEach((key) => {
    if (!['sortBy', 'per_page', 'page'].includes(key) && search.value[key]) {
      url.searchParams.append(key, search.value[key]);
    }
  });

  open(url, '_blank');
}

/**
 * @param {(object|null)} status
 * @returns {string}
 */
function getStatusText(status) {
  if (!status) {
    return 'Unknown';
  }

  let statusOption = statusOptions.find(
    (option) => option.value == status.status_id
  );

  if (statusOption) {
    return statusOption.abbr;
  }

  return status.status_name;
}
</script>
