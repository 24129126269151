<template>
  <a :class="textColor" :href="url">
    <span :class="{ 'd-inline-block': true, 'font-weight-bold': bold }">
      {{ department.company.handle_name }}
    </span>
    <span v-if="dep" class="d-inline-block font-italic">
      [{{ department.department }}]
    </span>
  </a>
</template>

<script setup>
import { computed, inject } from 'vue';

const $gates = inject('$gates');

const { color, department } = defineProps({
  color: {
    type: String,
    default: null,
  },
  department: {
    type: Object,
    required: true,
  },
  dep: {
    type: Boolean,
    default: false,
  },
  bold: {
    type: Boolean,
    default: false,
  },
});

const textColor = computed(() => (color ? color : 'text-reset'));

const url = computed(() => {
  if ($gates.hasPermission('department.view')) {
    return route('departments.show', [department.company_id, department.id]);
  }

  return null;
});
</script>
